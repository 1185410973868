/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { env } from '@/shared/config/env.mjs';

export const pageView = (path: string) => {
  window.ym(env.NEXT_PUBLIC_YM_ID, 'hit', path);
};

export const eventYM = (target: string, params?: ym.VisitParameters) => {
  window.ym(env.NEXT_PUBLIC_YM_ID, 'reachGoal', target, params);
};

export const YandexAnalyticsScript = () => {
  const router = useRouter();

  React.useEffect(() => {
    pageView(router.pathname);
  }, []);

  React.useEffect(() => {
    router.events.on('routeChangeComplete', pageView);
    router.events.on('hashChangeComplete', pageView);

    return () => {
      router.events.off('routeChangeComplete', pageView);
      router.events.off('hashChangeComplete', pageView);
    };
  }, []);

  return (
    <Script
      id="yandex-analytics"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(${env.NEXT_PUBLIC_YM_ID}, "init", {
        defer: true,
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        ecommerce:"dataLayer"
      });
      `,
      }}
    />
  );
};

export const YandexMetrikaNoScript = () => {
  return (
    <noscript>
      <div>
        <img
          src={`https://mc.yandex.ru/watch/${env.NEXT_PUBLIC_YM_ID}`}
          style={{ position: 'absolute', left: '-9999px' }}
          alt="yandex"
        />
      </div>
    </noscript>
  );
};
