import { AppProps } from 'next/app';

import { YandexAnalyticsScript, YandexMetrikaNoScript } from '@/shared/lib/analytics';

// eslint-disable-next-line react/display-name
export const withAnalytics = (Component: AppProps['Component']) => (props: AppProps) => {
  return (
    <>
      <YandexAnalyticsScript />
      <YandexMetrikaNoScript />

      <Component {...props} />
    </>
  );
};
