import { Flex, Text } from '@radix-ui/themes';
import { useUnit } from 'effector-react';
import { RiWifiOffLine } from 'react-icons/ri';

import { $offline, $online } from '@/shared/lib/network';

export const NetworkToast = () => {
  const [online] = useUnit([$online, $offline]);

  if (online) return null;

  return (
    <Flex
      className="bottom-0 left-2 right-2 rounded shadow-sm"
      align="center"
      gap="4"
      p="2"
      position="sticky"
      style={{ background: 'var(--gray-3)' }}
    >
      <Flex
        align="center"
        justify="center"
        className="rounded-full"
        width="6"
        height="6"
        style={{ background: 'var(--gray-6)' }}
      >
        <RiWifiOffLine />
      </Flex>

      <Flex direction="column">
        <Text size="3">You&apos;re offline now</Text>
        <Text size="2" color="gray">
          Oops! Internet is disconnected
        </Text>
      </Flex>
    </Flex>
  );
};
