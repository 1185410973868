import { useRouter } from 'next/router';
import { capitalize } from '@effable/misc';
import { NextSeo, NextSeoProps } from 'next-seo';

import { env } from '@/shared/config/env.mjs';

import { APP_TITLE } from './meta';

interface PageSeoProps extends NextSeoProps {
  image?: string;
}

export const PageSeo = (props: PageSeoProps) => {
  const { title = APP_TITLE, description, image, ...other } = props;

  const { asPath } = useRouter();

  const canonicalLink = `${env.NEXT_PUBLIC_APP_URL}${asPath}`;

  const imageURL = image?.startsWith('https://') ? image : `${env.NEXT_PUBLIC_APP_URL}${image}`;

  const capitalizedTitle = capitalize(APP_TITLE);

  return (
    <NextSeo
      robotsProps={{
        maxSnippet: -1,
        maxVideoPreview: -1,
        maxImagePreview: 'large',
      }}
      canonical={canonicalLink}
      title={title}
      defaultTitle={capitalizedTitle}
      description={description}
      titleTemplate={`${capitalizedTitle} - %s`}
      twitter={{
        site: capitalizedTitle,
        cardType: image ? 'summary_large_image' : 'summary',
      }}
      openGraph={{
        siteName: capitalizedTitle,
        locale: 'en',
        type: 'website',
        title,
        description,
        images: image
          ? [
              {
                url: imageURL,
                width: 800,
                height: 600,
                alt: 'Preview image',
              },
            ]
          : undefined,
      }}
      {...other}
    />
  );
};
